export const API_LOGIN = '/api/authentication/login';
export const API_LOGOUT = '/api/authentication/logout';
export const APP_LOGOUT = '/authentication/logout';
export const APP_LOGOUT_IMPERSONATED = '/authentication/logout?_switch_user=_exit';
export const API_PRODUCTS = `/api/products`;
export const API_IQUOTE = `/api/iquote`;
export const API_PRODUCTS_SEARCH = `/api/products/live-search`;
export const API_CATEGORIES = `/api/categories`;
export const API_ACCOUNT = '/api/account';
export const API_COUNTRIES = '/api/countries';
export const API_COUNTIES = '/api/counties';
export const API_PROFORM = '/api/proforma-invoice';
export const API_CHECKOUT_ADDRESSES = '/api/checkout-addresses';
export const API_DS_SHIPPING_ADDRESSES = '/api/ds-addresses';
export const API_PRODUCTS_EXPORT = `${API_PRODUCTS}/export`;
export const API_USER_SOLD = `${API_ACCOUNT}-sold`;
export const API_USER_CREDIT = `${API_ACCOUNT}-credit`;
export const API_STATIC_BLOCKS = '/api/static-blocks';
export const API_ORDER_OPTIONS = `${API_ACCOUNT}/order-options`;
export const API_ORDER_FEEDBACK = `/api/order-feedback`;
export const API_USER_PROFILE = `${API_ACCOUNT}/profile`;
export const API_USER_SETTINGS = `${API_ACCOUNT}/settings`;
export const API_PASSWORD_RECOVERY = `${API_ACCOUNT}/initialize-password-recovery`;
export const API_CART = `/api/shopping-cart`;
export const API_CART_REMOVE = `${API_CART}/remove`;
export const API_CART_EXPORT = `${API_CART}/export`;
export const API_WISHLISTS = '/api/wishlists';
export const API_WISHLISTS_EXPORT = `${API_WISHLISTS}/export`;
export const API_SOLD = `/api/sold`;
export const API_ORDERS = `/api/orders`;
export const API_USER_NOTIFICATIONS = '/api/messages';
export const API_RMA = '/api/rma';
export const API_COOKIE_POLICY = '/api/cookie-policy';
export const REGISTRATION = '/api/registration';
export const API_OVERDUE = '/api/overdue-info';
export const API_BALANCE = '/api/balance';
export const API_DUE_BALANCE = '/api/due-balance-notification';
export const API_ADDRESSES = '/api/addresses';
export const API_ADDRESSES_CODE = '/api/validation-code';
export const API_DASHBOARD = '/api/dashboard';
export const API_SMIS = '/api/smis/authentication/token';
export const API_INVOICES = '/api/invoices';
export const API_INVOICE_REFRESH = `${API_INVOICES}/refresh-button`;
export const API_INVOICE_TOTAL_AMOUNTS = `${API_INVOICES}/total-amounts`;
export const API_INVOICE_SYNCHRONIZATION = `/api/invoices-synchronization`;
export const API_INVOICES_FEED = `/api/invoices-feed`;
export const API_UNPAID_INVOICES_FEED = `/api/unpaid-invoices-feed`;
export const API_PAYMENTS_FEED = `/api/payments-feed`;
export const API_ORDER_INVOICE = '/api/order-invoice';
export const API_ORDERS_FEED = '/api/orders-feed';
export const API_USERS_LIST = '/api/users-list';
export const API_PAYMENTS = '/api/payments';
export const API_LOCKERS = '/api/lockers';

export const PUBLIC_ROUTES = [
  // '/information/cookies-policy',
  '/information/data-processing-policy',
];
